<template>
    <div class="table-management" v-if="timers && !Array.isArray(timers) && Object.values(timers).length > 0">
        <div class="table-body">
            <table v-if="Object.values(timersTpl).length > 0">
                <thead>
                    <tr>
                        <th></th>
                        <!-- 00h a 23h -->
                        <th v-for="header in tableData.headers" :key="header.value" class="hours">
                            <span>{{ header.text }}</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="timerData in tableData.rows">
                        <tr class="date">
                            <td colspan="25">
                                {{ timerData.date }}
                                <span class="dropdown" @click="toggleDates(timerData)" :class="[{'open': isVisibleDates[timerData.date]},{'close': !isVisibleDates[timerData.date]}]"></span>
                            </td>
                        </tr>
                        <tr v-for="timerInfo in timerData.rows" :class="{'hidden': isVisibleDates[timerData.date]}" class="data-timers">
                            <td class="title">
                                <span>{{ timerInfo.name }}</span>
                            </td>

                            <td v-for="intervals in timerInfo.intervals" class="timers">
                                <template v-for="interval in sortedIntervals(intervals)">
                                    <div v-if="interval && interval.checked">
                                        <div class="interval-container">
                                            <span class="interval" v-if="interval.isNotOnlyTimer">{{ interval.hour }}</span>
                                            <TableCreatedBy v-if="interval.employee_id && employees[interval.employee_id] && employees[interval.employee_id].name && employees[interval.employee_id].surname && employees[interval.employee_id].avatar"
                                                :avatar="employees[interval.employee_id].avatar"
                                                :name="employees[interval.employee_id].name"
                                                :surname="employees[interval.employee_id].surname"
                                                :tooltip = "true"
                                            >
                                            </TableCreatedBy>
                                        </div>
                                    </div>
                                    <div v-else-if="interval && !interval.checked">
                                        <div class="interval-container">
                                            <span class="interval" v-if="interval.isNotOnlyTimer">{{ interval.hour }}</span>
                                            <div class="sing-container tooltip" @click="singTimer(interval)">
                                                <div v-if="!isFuture(interval)">
                                                    <img class="sing" :src="css_image_url + 'sing_timer.svg'"></img>
                                                </div>                                              
                                                <span class="tooltip-text">{{ $t('tools.operationalTimer.sign') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
    <EmptyTable v-else :title="$t('empty_table.users_title_supervise')" :description="$t('empty_table.issues_empty_filter')"></EmptyTable>
</template>

<script>
import TableCreatedBy from '@/components/ui/TableCreatedBy.vue'
import EmptyTable from '@/components/ui/EmptyTable.vue'
export default {
    name: 'TableOperationalTimers',
    components: {
        TableCreatedBy,
        EmptyTable
    },
    props: {
        timers: {
            type: Object || Array,
            default: () => []
        }
    },
    data() {
        return {
            isVisibleDates: {}
        }
    },
    computed: {    
        timersTpl() {
            const templates = this.$store.getters['operationalTimers/getOperationalTimers']()
            return Object.values(templates).reduce((acc, curr) => {
                acc[curr.id] = curr.name
                return acc
            }, {})
        },
        css_image_url() {
            return css_image_url
        },
        employees() {
            return this.$store.getters['employee/getList']
        },
        tableData() {
               // To format the date
    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };
            //Headers 00h a 23h
            const schema = {
                headers: [],
                rows: []
            }
            schema.headers = Array.from({ length: 24 }, (_, i) => i).map((hour) => {
                return {
                    text: hour <= 9 ? `0${hour}h` : `${hour}h`,
                    value: hour
                }
            })
            if (Object.values(this.timersTpl).length > 0) {
                schema.rows = []
                for (const [date, timersInfo] of Object.entries(this.timers)) {
                    const formattedDate = formatDate(date);
                    const schemaRow = {
                        date: formattedDate
                    }
                    for (const [key, timers] of Object.entries(timersInfo)) {
                        schemaRow['rows'] = {
                            [key]: { 
                                name: this.timersTpl[key],
                                intervals: []
                            },
                            ...(schemaRow['rows'] || {})
                        }

                        for (let i = 0; i < 24; i++) {
                            const timeConverted = i <= 9 ? `0${i}` : `${i}`;
                            const filteredTimers = timers.filter(
                                (timer) => timer.alarm_date.split(' ')[1].split(':')[0] === timeConverted && timer.timer_id === key
                            );

                            if (filteredTimers.length > 0) {
                                filteredTimers.forEach((timer) => {
                                schemaRow['rows'][key].intervals[i] = [
                                    ...schemaRow['rows'][key].intervals[i] || [],
                                    {
                                    hour: timer.alarm_date.split(' ')[1].slice(0, 5),
                                    checked: timer.check_date ? true : false,
                                    check_date: timer.check_date,
                                    employee_id: timer.employee_id,
                                    business_date: timer.business_date,
                                    alarm_date: timer.alarm_date,
                                    timer_id: timer.timer_id,
                                    isNotOnlyTimer: (filteredTimers.length > 1),
                                    }
                                ];
                                });
                            } else {
                                schemaRow['rows'][key].intervals[i] = null;
                            }
                            }
                    }
                    schema.rows.push(schemaRow)
                }
            }

            // console.log('<< schema', schema)

            return schema
        }
    },
    methods: {
        sortedIntervals(intervals) {
            if (!intervals || intervals.length === 0) {
        return [];
      }
      // Sort intervals array by hour in descending order
      return intervals.slice().sort((a, b) => b.hour - a.hour);
    
        },
        singTimer(timerInterval) {
            this.$emit('singTimer', timerInterval)
        },
        isFuture(interval) {
            const now = moment();
            const intervalDateTime = moment(interval.alarm_date, 'YYYY-MM-DD HH:mm');
            return intervalDateTime.isAfter(now);
        },
        toggleDates(timerDate) {
            this.isVisibleDates = {
                ...this.isVisibleDates,
                [timerDate.date]: !this.isVisibleDates[timerDate.date]
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.table-management {
    background-color: $color-white;
    width: 100%;
    border-radius: 4px;
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  
    table {
        width: 100%;
        tr {
            &.date {
                background-color: $color-neutral-500;
                font-weight: bold;

                .dropdown{
                    display: inline-block;
                    background: url('/img/up_caret_light.svg');
                    width: 10px;
                    height: 10px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    margin-left: 4px;
                    &.open {
                        transform: rotate(90deg);
                    }
                    &.close {
                        transform: rotate(180deg);
                    }
                }
            }
            &.hidden {
                display: none;
            }
            td,
            th {
                padding: 8px 6px;

                &.hours {
                    border-left: solid 1px $color-neutral-300;
                    border-right: solid 1px $color-neutral-300;
                    text-align: center;

                    span {
                        @include font-size(xs);
                    }
                }
            }

            td {
                &.timers {
                    background-color: $color-neutral-200;
                    border: solid 1px $color-neutral-300;
                    text-align: center;
                    &:has(.interval-container) {
                        background-color: $color-white;

                        
                    }
                    .interval-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        margin-bottom: 4px;
                        
                        .interval{
                            @include font-size(12px);
                        }
                        .sing-container {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            .sing {
                                width: 20px;
                                height: 20px;
                                cursor: pointer;
                            }
                            .tooltip-text {
                                position: absolute;
                                left: 50%;
                                bottom: 0;
                                padding: 2px 4px;
                                transform: translate(-50%, 70%);
                                background-color: $color-secondary-500;
                                border-radius: 4px;
                                display: none;
                                transition: all 0.3s ease-in-out;
                                color: $color-white;
                                @include font-size(xs);
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: -8px;
                                    left: 50%;
                                    transform: translate(-50%, 0);
                                    border: 4px solid transparent;
                                    border-bottom-color: $color-secondary-500;
                                    transition: all 0.3s ease-in-out;
                                }
                            }

                            &:hover {
                                .tooltip-text {
                                    display: inline;
                                }
                            }
                        }
                        
                    }
                }
                &.title {
                    border-top: solid 1px #E2E2E2;
                    border-right: solid 1px #E2E2E2;
                    border-bottom: solid 1px #E2E2E2;
                    border-left: none;
                    text-align: left;
                    max-width: 200px;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    span {
                        @include font-size(xs);
                        font-family: SourceSans3-Regular;
                        font-weight: 200;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
</style>
